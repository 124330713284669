/** @jsxImportSource @emotion/react */

import React, { useState } from 'react';
import { driverMessages, pharmacyData, Standort } from '../../data/pharmacyData';
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, Container, List, ListItem, Paper, Typography } from '@mui/material';
import PharmacyButton from './PharmacyButton';
import { WhatsAppLink } from '../../UI/UiWhatsappLink';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import UiPhoneLink from '../../UI/UiPhoneLink';
import { css } from '@emotion/react'
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';

const Pharmacy = () => {
  const [selectedGate, setSelectedGate] = useState<string | null>(null);
  const selectedGateData = pharmacyData.find((gate) => gate.gateNum === selectedGate);

  return (
    <Container maxWidth='sm' >
      {/* <div style={{ marginTop: '2rem' }} />
      <h2>Wählen Sie eine Tour Nr:</h2>

      <List style={{ display: 'flex' }}>
        {pharmacyData.map((gate) => (
          <ListItem key={gate.gateNum}>
            <Button onClick={() => setSelectedGate(gate.gateNum)}
              variant='contained'
            >
              {gate.gateNum}
            </Button>
          </ListItem>
        ))}
      </List>




      {
        selectedGateData && (
          <div>
            <h2 style={{ textAlign: 'center', fontSize: '2rem' }}>Tour: {selectedGate}</h2>
            <List>
              {selectedGateData.pharmacies.map((pharmacy) => (
                <ListItem key={pharmacy.pharmacyName}>
                  <PharmacyButton
                    pharmacyName={pharmacy.pharmacyName}
                    pharmacyAddress={pharmacy.pharmacyAddress}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        )
      }

      <div style={{ marginTop: '2rem' }} />
      <h4 style={{ textAlign: 'center' }}>Melden Sie eine Stauung oder ein Problem an einem der Tour.</h4> <br />
      <div css={mCss.ContactCss}>
        {
          driverMessages.map((call) => (
            <React.Fragment key={call.phone}>
              <p>{call.normalText}</p>
              <Card css={mCss.callCss}>
                  {call.Whatsapp && (
                    <Button
                    variant='contained'
                    style={{ height: '3rem' }}
                    component="a"
                    href={WhatsAppLink({
                      whatsapp: call.Whatsapp,
                      message: call.message
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <WhatsAppIcon style={{ fontSize: '2.5rem' }} />
                  </Button>
                  )}

                  {call.phone && (
                  <UiPhoneLink
                    phone={call.phone}
                    style={{ fontSize: '2.5rem', height: '3rem' }}
                  >
                  </UiPhoneLink>
                  )}
              </Card>
            </React.Fragment>
          ))
        }
      </div>

      <div>
      <h2 style={{ textAlign: 'center', fontSize: '2rem', marginTop: '2rem' }}>Adressen  </h2>

        {
          Standort.map((data, index) => {
            return(
          <Accordion key={index}>
                <AccordionSummary expandIcon={<ArrowDownwardIcon />} aria-controls="panel1-content" id="panel1-header">
                  <Typography>{data.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>

                    {data.address}
                    <Button>
                      <a href={`https://www.google.com/maps/search/?api=1&query=${data.address}`}
                       target="_blank" rel="noopener noreferrer">
                        <AddLocationAltIcon style={{color:'#1976D2', fontSize: '2rem' }}/>
                        </a>
                    </Button>
                    
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          })
        }

        
      </div> */}
    </Container >
  );
};


const mCss = {
  ContactCss: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    height: 'Auto',
    gap: '1rem',
    '@media (max-width: 700px)': {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: '1rem',
      textAlign: 'center',
    },
  }),
  callCss: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '1rem',
    height: '5rem',
    '@media (max-width: 700px)': {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: '1rem',
      textAlign: 'center',
      height: '8rem',
    },
  }),
}


export default Pharmacy;