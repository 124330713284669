import { useState } from 'react'
import { SubmitHandler, useWatch, useFormContext } from 'react-hook-form'
import {
  Container,
  Stack,
  Button,
  Paper,
  Typography,
  CircularProgress,
} from '@mui/material'
import { axiosInstance } from '../../../utils/axiosInstance'
import { BgiTextField } from '../../../UI/BgiTextField'
import countries from '../../../data/countries'
import BgiAutocomplete from '../../../UI/BgiAutocomplete'
import BgiRadioGroup from '../../../UI/BgiRadioGroup'
import { BgiToggleButtonGroup } from '../../../UI/BgiToggleButtonGroup'
import { buildGetErrMsg } from '../../../utils/buildGetErrMsg'
import { UserI } from '../../../types/user'
import {
  useCreateUserMutation,
  useUpdateUserMutation,
} from '../../../store/usersApi'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { BgiLink } from '../../../UI/BgiLink'
import { BgiCheckbox } from '../../../UI/BgiCheckbox'

const SUCCESS_MSGS = {
  create: (userId: string) => (
    <p>
      User has been created successfully with{' '}
      <BgiLink color="#fff" to={`/data-entry/users/${userId}/update`}>
        #{userId}
      </BgiLink>
    </p>
  ),
  update: 'User has been updated successfully',
}

const getErrMsg = buildGetErrMsg({
  USER_EXISTS: 'There is already an created account with this email',
})

export default function UserForm() {
  const { handleSubmit, control, reset } = useFormContext<UserI>()
  const [isPassNotMatch, setIsPassNotMatch] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [createUser] = useCreateUserMutation()
  const [updateUser] = useUpdateUserMutation()
  const userId = useWatch({ control, name: 'id' })
  const isCreateForm = !userId

  const role = useWatch({ control, name: 'role' })
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit: SubmitHandler<UserI> = async (data) => {
    if (
      typeof data.password === 'string' &&
      typeof data.repeatPassword === 'string' &&
      (data.password.length > 0 || data.repeatPassword.length > 0) &&
      data.password !== data.repeatPassword
    ) {
      return setIsPassNotMatch(true)
    } else {
      setIsPassNotMatch(false)
    }

    setIsCreating(true)

    const result = await (userId
      ? updateUser({ id: userId, ...data })
      : createUser(data))

    if ('data' in result) {
      enqueueSnackbar({
        message: isCreateForm
          ? SUCCESS_MSGS.create(result.data.id)
          : SUCCESS_MSGS.update,
        variant: 'success',
      })

      if (isCreateForm) reset()
    } else {
      enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
    }

    setIsCreating(false)
  }

  return (
    <Container maxWidth="sm">
      <BgiLink to={'/data-entry/users'}>Back to the users page</BgiLink>

      <div style={{ height: 30 }} />

      <Paper style={{ padding: '1rem' }} elevation={3}>
        <Typography
          style={{
            textAlign: 'center',
            marginBottom: '1rem',
            fontWeight: 700,
          }}
          variant="h5"
        >
          {userId ? 'Update' : 'Create'} User
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <BgiTextField label="Email" name="email" required />

            <BgiTextField
              label="Password"
              type="password"
              name="password"
              required={!userId}
              minLength={6}
              maxLength={128}
            />

            <div>
              <BgiTextField
                label="Repeat password"
                type="password"
                name="repeatPassword"
                required={!userId}
                minLength={6}
                maxLength={128}
              />

              {isPassNotMatch && (
                <Typography
                  variant="body1"
                  style={{ color: '#f44336', marginTop: 2 }}
                >
                  كلمة السر غير متطابقة
                </Typography>
              )}
            </div>

            <BgiTextField
              label="Name"
              name="name"
              required
              minLength={2}
              maxLength={32}
            />

            <BgiTextField
              label="Last Name"
              name="lastName"
              required
              minLength={2}
              maxLength={32}
            />

            <BgiTextField
              label="Nickname"
              name="nickName"
              required
              minLength={2}
              maxLength={32}
              placeholder='يمنع كتابة أرقام الهواتف والكلمات البذيئة'
            />

            <BgiCheckbox
              label='Is Nickname approved?'
              name="isNickNameApproved"
             />

            <BgiRadioGroup
              name="gender"
              label="Gender"
              options={[
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
              ]}
              required
            />

            <BgiTextField label="class Level" name="classLevel" />

            <BgiTextField label="city" name="city" />
            <BgiTextField label="mobile" name="mobile" />
            <BgiTextField label="whatsapp" name="whatsapp" />

            <BgiAutocomplete
              name="country"
              size="small"
              label="Country"
              options={countries}
              groupBy={(country) => country.name[0]}
              getOptionLabel={(option) => option.name}
              fullWidth
              isOptionEqualToValue={(option, value) =>
                option.code === value.code
              }
            />

            <BgiToggleButtonGroup
              label="Account Type"
              name="role"
              options={[
                { label: 'Admin', value: 'admin', disabled: true },
                { label: 'Worker', value: 'worker', disabled: true },
                { label: 'Student', value: 'student' },
                { label: 'Teacher', value: 'teacher', disabled: true },
                { label: 'Typist', value: 'typist', disabled: true },
              ].map((option) =>
                isCreateForm ? option : { ...option, disabled: true }
              )}
              required
            />

            <BgiCheckbox
              label='Is Bot?'
              name="isBot"
             />

            {role !== 'teacher' && (
              <>
                <BgiTextField label="School Name" name="school" />

                <BgiRadioGroup
                  name="schoolType"
                  label="School Type"
                  options={[
                    { label: 'StateSchool', value: 'stateSchool' },
                    { label: 'PrivateSchool', value: 'privateSchool' },
                  ]}
                />
              </>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              startIcon={isCreating && <CircularProgress size={20} />}
              disabled={isCreating}
            >
              {userId ? 'Update' : 'Create'} User
            </Button>
          </Stack>
        </form>
      </Paper>

      <div style={{ height: 30 }} />
    </Container>
  )
}
