import { WhatsApp } from "@mui/icons-material";
import { title } from "process";

interface driverMessagesT {
  Supervisor: string;
  phone?: string;
  Whatsapp?: string;
  message?: string;
  icon: string;
  normalText: string;
}

export const pharmacyData = [
  {
    gateNum: '0909',
    pharmacies: [
      { pharmacyName: 'im Dorf Apotheke', pharmacyAddress: 'Nienstedtener Str. 10, 22609 Hamburg' },
      { pharmacyName: 'Schwanen-Apotheke', pharmacyAddress: 'Hamburg, Rugenbarg 9, 22549 Hamburg' },
      { pharmacyName: 'Neue Osdorfer Apotheke', pharmacyAddress: 'Langelohstraße 158, 22549 Hamburg' },
      { pharmacyName: 'Schwalben Apotheke Rissen', pharmacyAddress: 'Wedeler Landstraße 44, 22559 Hamburg' },
      { pharmacyName: 'Apo-rot Apotheke Blankenese', pharmacyAddress: 'Blankeneser Bahnhofstraße 58, 22587 Hamburg' },
      { pharmacyName: 'Johannis Apotheke', pharmacyAddress: 'Blankeneser Bahnhofstraße 26, 22587 Hamburg' },
      { pharmacyName: 'Heine Apotheke Blankenese', pharmacyAddress: 'Erik-Blumenfeld-Platz 1, 22587 Hamburg' },
      { pharmacyName: 'Eichen Apotheke Iserbrook', pharmacyAddress: 'Heidrehmen 2, 22589 Hamburg' }
    ]
  },
  {
    gateNum: '1238',
    pharmacies: [
      { pharmacyName: 'Hubertus Apotheke', pharmacyAddress: 'Achternfelde 4, 22850 Norderstedt' },
      { pharmacyName: 'im Herold-Center Norderstedt', pharmacyAddress: 'Berliner Allee 40B, 22850 Norderstedt' },
      { pharmacyName: 'Eulen Apotheke Quickborn', pharmacyAddress: 'Am Freibad 4A, 25451 Quickborn' },
      { pharmacyName: 'Ziegenwag Apotheke Ziegenwag', pharmacyAddress: 'Ziegenwag, 25451 Quickborn' },
      { pharmacyName: 'Privilegierte Apotheke', pharmacyAddress: 'Reichenstraße 2, 25355 Barmstedt' },
    ],
  }, {
    gateNum: '1706',
    pharmacies: [
      { pharmacyName: 'Volkspark Apotheke', pharmacyAddress: 'Kieler Str 411, 22525 Hamburg' },
      { pharmacyName: 'Florian Apotheke', pharmacyAddress: 'Privatweg 2-4, 22527 Hamburg' },
      { pharmacyName: 'Domino-Apotheke', pharmacyAddress: 'Vogt-Wells-Straße 14, 22529 Hamburg' },
      { pharmacyName: 'Vita Apotheke', pharmacyAddress: 'Heußweg 37, 20255 Hamburg' },
      { pharmacyName: 'Laurin Apotheke', pharmacyAddress: 'Osterstraße 133, 20255 Hamburg' },
      { pharmacyName: 'Eichen Apotheke Eimsbüttel', pharmacyAddress: 'Osterstraße 36, 20259 Hamburg' },
    ],

  }
];



// -----------------------------------------------------------
// -----------------------------------------------------------
export const driverMessages = [
  {
    Supervisor: 'Feras',
    normalText: 'Kontakt mit fears',
    phone: '4917623472897',
    Whatsapp: '4917623472897',
    message: `
    مرحبا:
     في أزدحام على الطريق، سوف نتأخر عن موعد التسليم حوالي 20 دقيقة`,
    icon: '/',
  },

  // {
  //   Supervisor: 'Emily',
  //   normalText: 'Kontakt mit Emily',
  //   phone: '4917630689215',
  //   Whatsapp: '4917630689215',
  //   message:
  //     `
  //   Hallo,
  //    Es gibt einen Stau auf der Straße, Ich werde mich um etwa 20 Minuten verspäten.`,
  //    icon: '/',
  // },
  {
    Supervisor: 'whatsapp Diesel group',
    normalText: 'Schreiben an die Diesel-Group',
    Whatsapp: '',

    icon: '/',
  },

]


// -----------------------------------------------------------
// -----------------------------------------------------------
export const Standort = [
  {
    title: 'Alliance Healthcare Ggehe',
    address: 'Brandstücken 18, 22549 Hamburg',
    description: '',
    imgUrl: '/',
  },
  {
    title: 'Tankstelle: tankpool24',
    address: 'Ottensener str, 22765 Hamburg',
    description: '',
    imgUrl: '/',

  },
]