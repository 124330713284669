/** @jsxImportSource @emotion/react */
import { useMemo, FC, useState, useCallback, useEffect, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { FormProvider, useForm } from 'react-hook-form'
import { slideDefaultValues, slideViews } from './slide-view'
import { FileHash, Slide } from '../../types/slide'
import { Unit } from '../../types/unit'
import { Button, CircularProgress, Stack } from '@mui/material'
import {
  SubmitAnswerPayload,
  goToSlide,
  nextSlide,
  prevSlide,
  submitAnswer,
  toggleSlideMark,
} from '../../store/slides'
import SlidesResult from './SlidesResult'
import NextChapter from './NextChapter'
import { Course } from '../../types/course'
import { BasicChapter } from '../../types/chapter'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import SlideFeedbackForm from '../feedback/SlideFeedbackForm'
import { FeedbackFv } from '../../types/feedback'
import Audio from '../../UI/Audio'
import { FILES_BASE_URL } from '../../constants/FILES_BASE_URL'
import SlideStickyAd from './SlideStickyAd'
import MoreInfo from '../slide-old/MoreInfo'
import { Ad } from '../../types/ad'
import { DirT } from '../../types/dir'
import { css } from '@emotion/react'
import BgiButton from '../../UI/BgiButton'
import { useTranslation } from 'react-i18next'
import ScreenshotButton from '../screenshot/ScreenshotButton'
import { playSound } from '../../UI/AudioPlayer'
import { useCreateExamResultMutation } from '../../store/examResultApi'
import { useStore } from 'react-redux'

type DoneFn = () => void;

export interface SlidesExposedAPI {
  submit: (done?: DoneFn) => void
  clickNextBtn: () => void
}

export interface SlidesProps {
  dir: DirT
  slides: Slide[]
  filesHashes: FileHash[]
  units: Unit[]
  course?: Course
  courseChapters?: BasicChapter[]
  curChapterId?: string
  isCurExam?: boolean
  stickyAds: (Ad | null)[]
  isThereBannerAd: boolean
  onPreNextSlide?: (currentSlideIdx: number, userAnswer: any) => void
  exposeAPI?: (api: SlidesExposedAPI) => void
  hidePrevBtn?: boolean
  isNavigatingNext?: boolean
}

const AFTER_SUBMIT_TIMEOUT = 1000


// ---comp----------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------

const Slides: FC<SlidesProps> = ({
  dir,
  slides,
  filesHashes,
  units,
  course,
  courseChapters,
  curChapterId,
  isCurExam,
  stickyAds,
  isThereBannerAd,
  onPreNextSlide,
  hidePrevBtn,
  exposeAPI,
  isNavigatingNext
}) => {
  const curSlideIdx = useAppSelector((state) => state.slides.curSlideIdx)
  const lastReachedIdx = useAppSelector((state) => state.slides.lastReachedIdx)
  const markedSlides = useAppSelector((state) => state.slides.markedSlides)
  const answerResults = useAppSelector((state) => state.slides.answerResults)
  const userMe = useAppSelector((state) => state.auth.userMe)
  const curSlide = slides[curSlideIdx]
  const dispatch = useAppDispatch()
  const isWaiting = useRef(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const [isShowResult, setIsShowResult] = useState(false)
  const [isShowFeedbackForm, setIsShowFeedbackForm] = useState(false)
  const [isSendingFeedback, setIsSendingFeedback] = useState(false)
  const [isShowMorInfo, setIsShowMorInfo] = useState(false)
  const [isSendingResults, setIsSendingResults] = useState(false)
  const isResultSentReqFired = useRef(false)

  const [createExamResult] = useCreateExamResultMutation();

  const store = useStore();

  const {
    teacherCourseToken: teacherCourseTokenParam,
    courseId: courseIdParam
  } = useParams() as { teacherCourseToken?: string, courseId?: string };

  // ---------------------------------------------------------
  const isNoQuestionSlide = useMemo(
    () =>
      !slides.some(
        (slide) =>
          slide.type === 'question-checkboxes'
          || slide.type === 'question-cards'
          || slide.type === 'question-multi-inputs'
          || slide.type === 'question-input'
      ),
    [slides]
  )
  // ---------------------------------------------------------
  const screenshotRef = useRef<HTMLDivElement>(null);

  const stickyAd = stickyAds[curSlideIdx]

  const feedbackForm = useForm<FeedbackFv>()

  const userAnswers = useAppSelector((state) => state.slides.userAnswers)

  const navigate = useNavigate()

  const getFormAnswerValue = useCallback(
    (slideIdx: number) => {
      const slide = slides[slideIdx]

      if (!slide) return undefined

      if (!(slide.type in userAnswers)) return

      const slideType = slide.type as keyof typeof userAnswers

      const userAnswer =
        slide.type in userAnswers
          ? userAnswers[slideType][slide.id]?.value
          : undefined

      return userAnswer ?? slideDefaultValues[slideType]
    },
    [slides, userAnswers]
  )

  const formContext = useForm<{ userAnswer: any }>({
    defaultValues: {
      userAnswer: getFormAnswerValue(curSlideIdx),
    },
  })
  const isRequireAnswerSubmit = useMemo(() =>
    curSlide.type in userAnswers
      ? !userAnswers[curSlide.type as keyof typeof userAnswers][curSlide.id]
      : false,
    [curSlide, userAnswers]
  )

  // -------------------------------------------------------------------------------
  const totalPoints = useMemo(() =>
    slides.reduce(
      (acc, slide) => acc + ('point' in slide ? slide.point : 0), 0),
    []
  )

  const userTotalPoints = useAppSelector(state => state.slides.userTotalPoints)

  const calcPercentageResult = (userTotalPoints: number) => totalPoints === 0
  ? 0
  : Number(((userTotalPoints / totalPoints) * 100).toFixed(2));

  const percentageResult = calcPercentageResult(userTotalPoints);
  // -------------------------------------------------------------------------------

  const curUnit = useMemo(
    () => units.find((unit) => slides[curSlideIdx].unitId === unit.id),
    [units, curSlideIdx]
  )

  const curAudioFile = useMemo(() => {
    if ('audioFileId' in curSlide && curSlide.audioFileId) {
      return (
        filesHashes.find((file) => file.id === curSlide.audioFileId) ?? null
      )
    }

    return null
  }, [curSlide])

  const SlideView = slideViews[curSlide.type]


  // -----------------------------------------------------
  const navigateToNext = async () => {
    const storeState = store.getState() as any
    if (storeState.slides.curSlideIdx + 1 < slides.length) {
      formContext.setValue('userAnswer', getFormAnswerValue(storeState.slides.curSlideIdx + 1))
      feedbackForm.reset()
      dispatch(nextSlide())
    } else {
      if (isNoQuestionSlide) {
        nextChapter()
      } else {
        if (teacherCourseTokenParam && !isResultSentReqFired.current) {
          isResultSentReqFired.current = true;

          setIsSendingResults(true);

          // TODO: send results to teacher
          const finalPercentageResult = calcPercentageResult(storeState.slides.userTotalPoints.toFixed(2));
          await createExamResult({
            isExamGeneratedByTeacher: true,
            token: teacherCourseTokenParam,
            percentageResult: finalPercentageResult,
            courseId: courseIdParam
          });

          setIsSendingResults(false);
        }

        setIsShowResult(true)
        setIsCompleted(true)
      }
    }
  }
  // -----------------------------------------------------


  // --NC---------------------------------------------------
  const nextChapter = () => {
    if (courseChapters && curChapterId) {
      const curChapterIdx = courseChapters.findIndex(
        (chapter) => chapter.id === curChapterId
      )

      const isLastChapter = curChapterIdx + 1 === courseChapters.length

      navigate(
        isLastChapter
          ? `/courses/${courseChapters[curChapterIdx].courseId}/exam`
          : `/chapters/${courseChapters[curChapterIdx + 1].id}/slides`,
        { replace: true }
      )
    }
  }
  // ---------------------------------------------------NC--

  const handleSubmit = (done?: DoneFn) => {
    if (isWaiting.current) return

    isWaiting.current = true

    if (isShowResult) {
      nextChapter()
      isWaiting.current = false
      return
    }

    const curSlide = slides[(store.getState() as any).slides.curSlideIdx] as SubmitAnswerPayload['slide'];

    if (isRequireAnswerSubmit) {
      dispatch(
        submitAnswer({
          slide: curSlide,
          userAnswer: formContext.getValues().userAnswer,
        })
      )

      setTimeout(() => {
        navigateToNext()
        isWaiting.current = false
        done?.()
      }, AFTER_SUBMIT_TIMEOUT)
    } else {
      navigateToNext()
      isWaiting.current = false
      done?.()
    }
  }

  const handleNext = () => {
    if (onPreNextSlide) {
      const userAnswer = formContext.getValues().userAnswer;
      onPreNextSlide(curSlideIdx, userAnswer);
    } else {
      handleSubmit();
    }
  }

  const handlePrev = () => {
    if (curSlideIdx - 1 < 0) return

    if (isShowResult) {
      setIsShowResult(false)
    } else {
      formContext.setValue('userAnswer', getFormAnswerValue(curSlideIdx - 1))
      feedbackForm.reset()
      dispatch(prevSlide())
    }
  }

  const navigateToSlide = (slideIdx: number) => {
    formContext.setValue('userAnswer', getFormAnswerValue(slideIdx))
    feedbackForm.reset()
    dispatch(goToSlide(slideIdx));
  }

  // -----------------------------------------------------------
  const openFeedbackForm = () => {
    if (!isWaiting.current && !isSendingFeedback) {
      setIsShowFeedbackForm((prevShow) => !prevShow)
    }
  }

  const openMoreInfo = () => {
    setIsShowMorInfo((prevShow) => !prevShow)
  }
  // -----------------------------------------------------------


  const accomplishment = isCompleted
    ? 100
    : (lastReachedIdx / slides.length) * 100

  const submitBtnRef = useRef<HTMLButtonElement | null>(null)

  const isEffectCalled = useRef(false)
  useEffect(() => {
    if (isEffectCalled.current) return

    isEffectCalled.current = true

    exposeAPI?.({
      submit: handleSubmit,
      clickNextBtn: handleNext,
    })

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        submitBtnRef.current?.click()
      }
    }

    document.addEventListener('keydown', handleKeydown)
    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [])

  const isConsumer =
    !userMe || (userMe.role !== 'admin' && userMe.role !== 'worker')

  // ---------------------------------------------
  const { t } = useTranslation(['Slide', "SlideHeader"])


  // ----return-----------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------
  return (
    <div css={styles.root} dir={dir}>
      <div css={styles.box}>
        <FormProvider {...formContext}>
          {!isShowResult && (
            <>
              <div css={styles.header} style={{ direction: 'rtl' }}>
                {curSlide.slideNum && (
                  <span>{curSlide.slideNum}</span>
                )}
                <ScreenshotButton screenshotRef={screenshotRef} />

                {(!course || course.isPointsVisible) && (
                  <span>Point: {'point' in curSlide ? curSlide.point : 0}</span>
                )}

                <BgiButton
                  color="inherit"
                  disableRipple
                  onClick={() => dispatch(toggleSlideMark(curSlide.id))}
                  isShowResult={isShowResult}
                >
                  {t("SlideHeader:Mark")}
                </BgiButton>


                {/* ---moreInfo----------------------------------------- */}

                {(curSlide.moreInfo && curSlide.moreInfo.length > 0) && (
                  <BgiButton
                    color="inherit"
                    disableRipple
                    onClick={openMoreInfo}
                    disabled={isShowFeedbackForm}
                    isCurExam={isCurExam}
                  >
                    {t('SlideHeader:MoreInfo')}
                  </BgiButton>
                )}
                {/* -----------------------------------------moreInfo--- */}

                {(!course || course.isButtonCommentsVisible) && (
                  <BgiButton
                    color="inherit"
                    disableRipple
                    onClick={openFeedbackForm}
                    disabled={isShowMorInfo}
                    isCurExam={isCurExam}
                  >
                    {t('SlideHeader:Comment')}
                  </BgiButton>
                )}

                <span>{t('SlideHeader:accompli')} %{Math.trunc(accomplishment)}</span>
              </div>

              {!isShowFeedbackForm && (
                <div
                  css={styles.unitHeader}
                  style={{
                    background:
                      markedSlides.includes(curSlide.id)
                        ? '#fff176'
                        : answerResults[curSlide.id] === false
                          ? 'linear-gradient(to bottom, #a90329 0%,#8f0222 44%,#6d0019 100%)'
                          : answerResults[curSlide.id] === true
                            ? 'linear-gradient(to bottom, #7abcff 0%,#60abf8 0%,#60abf8 59%,#4096ee 100%)'
                            : undefined,
                    justifyContent: 'space-between',

                    color:
                      answerResults[curSlide.id] === false
                        ? 'whitesmoke'
                        : answerResults[curSlide.id] === true
                          ? 'whitesmoke'
                          : undefined
                  }}
                >
                  <Stack direction="row" spacing={3}>
                    {course &&
                      courseChapters &&
                      (curChapterId || isCurExam) && (
                        <NextChapter
                          course={course}
                          courseChapters={courseChapters}
                          curChapterId={curChapterId}
                          isCurExam={isCurExam}
                          dir={dir}
                        />
                      )}

                    {curAudioFile && (
                      <Audio
                        src={`${FILES_BASE_URL}/${curAudioFile.fileHash}`}
                        stopClickPropagation
                      />
                    )}
                  </Stack>

                  {curUnit && <span>{curUnit.briefTitle}</span>}
                </div>
              )}
            </>
          )}

          <div>
            {isShowMorInfo && (
              <MoreInfo
                moreInfo={curSlide.moreInfo}
                onClose={() => setIsShowMorInfo(false)}

              />
            )
            }
          </div>


          <div css={styles.slideBody} style={{ direction: 'ltr' }} ref={screenshotRef} data-name="fuad">
            <div style={{ direction: dir, width: '100%', height: '100%' }} data-name="backgroundF ff feedback">

              {isShowResult ? (
                <SlidesResult percentageResult={percentageResult} isThereBannerAd={isThereBannerAd} setIsShowResult={setIsShowResult} navigateToSlide={navigateToSlide} />
              ) : isShowFeedbackForm ? (
                <FormProvider {...feedbackForm}>
                  <SlideFeedbackForm
                    slideId={curSlide.id}
                    isSending={isSendingFeedback}
                    setIsSending={setIsSendingFeedback}
                    onClose={() => setIsShowFeedbackForm(false)}
                  />
                </FormProvider>
              ) : (
                !isShowMorInfo &&
                <SlideView
                  key={curSlide.id}
                  // @ts-ignore
                  slide={curSlide}
                  filesHashes={filesHashes}
                  isCurExam={isCurExam}
                  nextSlide={handleNext}
                  prevSlide={handlePrev}
                />
              )}
            </div>
          </div>

          <div data-consumer={isConsumer}>
            {!isShowFeedbackForm && !isShowMorInfo && (
              <>
                <div css={styles.footerCss}>
                  {!hidePrevBtn && (
                    <Button
                      css={styles.prevBtn}
                      variant="contained"
                      onClick={() => {
                        playSound("clickShow.wav"); 
                        handlePrev(); 
                      }}
                    >
                      {t('Back')}
                    </Button>
                  )}

                  {(!isShowResult ||
                    (percentageResult >= 95 && !isCurExam)) && !isShowMorInfo && (
                      <Button
                        css={styles.nextBtn}
                        ref={submitBtnRef}
                        variant="contained"
                        onClick={() => {
                          playSound("clickShow.wav"); 
                          handleNext();
                        }
                        }
                        disabled={isNavigatingNext || isSendingResults}
                        startIcon={isNavigatingNext ? <CircularProgress size={16} /> : undefined}
                      >
                        {t('Next')}
                      </Button>
                    )}
                </div>


                {((isCompleted && !isShowResult) ||
                  (percentageResult >= 95 && !isCurExam)) && (
                    <Button
                      ref={submitBtnRef}
                      variant="contained"
                      onClick={() => setIsShowResult(true)}
                      disableRipple
                    >
                      {t('SlideHeader:ReturnToResult')}
                    </Button>
                  )}


                {isConsumer && stickyAd && !isShowResult && (
                  <SlideStickyAd ad={stickyAd} />
                )}

                {(userMe?.role === 'admin') &&
                  !isShowResult && (
                    <span style={{ flexShrink: 0 }}>
                      <Button
                        style={{ minWidth: 72 }}
                        variant="contained"
                        disableRipple
                        component="a"
                        href={`/data-entry/slides/${curSlide.id}/update`}
                        target="_blank"
                      >
                        {t('Update')}
                      </Button>
                    </span>
                  )}

              </>
            )}
          </div>
        </FormProvider>
      </div>
    </div >
  )
}

const styles = {
  root: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100% - 1.2rem)',
  }),
  header: css({
    display: 'flex',
    backgroundColor: 'var(--clrBg)',
    color: '#fff',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 1rem',
    flexWrap: 'wrap',
    flexShrink: 0,
    '& > *': {
      fontSize: 14,
    },
    '@media screen and (max-width: 676px)': {
      padding: '0 0.5rem',
      display: 'grid',
      gridTemplateColumns: 'repeat(7, auto)',
      width: '100%',
      textAlign: 'center',
      '& > *': {
        fontSize: 11,
      },
      '& > button': {
        fontSize: 11,
      },
    },
  }),

  unitHeader: css({
    fontWeight: 400,
    fontSize: 14,
    borderBottom: '1px solid #e0e0ee',
    flexShrink: 0,
    padding: '0.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
    height: '2.25rem',
  }),
  slideBody: css({
    flexGrow: 1,
    overflow: 'auto',
    padding: '1rem'
  }),
  box: css({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 852,
    width: '100%',
    maxHeight: 680,
    background: '#fafafa',
    height: '100%',
    borderRadius: 8,
    overflow: 'hidden',
    boxShadow:
      '0px 3px 3px -2px rgba(0,0,0,0.2), ' +
      '0px 3px 4px 0px rgba(0,0,0,0.14), ' +
      '0px 1px 8px 0px rgba(0,0,0,0.12)',
    '@media screen and (max-width: 676px)': {
      maxHeight: 'initial',
      borderRadius: 0,
      boxShadow: 'initial',
    },
  }),
  footerCss: css({
    display: 'flex',
    padding: '0.75rem 0.5rem',
    alignItems: 'center',
    '&[data-consumer="false"]': {
      justifyContent: 'space-between',
    },
    '@media screen and (max-width: 676px)': {
      padding: '0.5rem 0.5rem',
      width: '100%',
    },
    '@media (max-width: 564px)': {
      '&[data-consumer="true"]': {
        display: 'flex',
        flexDirection: 'column-reverse',
      },
    },
  }),
  nextBtn: css({
    minWidth: '7rem',
    margin: '0.5rem 4rem 0.5rem 2rem',
    marginLeft: '2rem',

  }),
  prevBtn: css({
    minWidth: '7rem',
    height: '2.5rem',
    marginRight: '4rem',
    marginLeft: '2rem',
  })
}

export default Slides
