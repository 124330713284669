import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { QuestionCardsSlide, QuestionCheckboxesSlide, QuestionInputSlide, QuestionMultiInputsSlide } from '../types/slide'
import { checkSlideQuestionCheckboxAnswer } from '../components/slides/slide-view/SlideViewQuestionCheckboxes'
import { checkSlideQuestionInputAnswer } from '../components/slides/slide-view/SlideViewQuestionInput'
import { checkSlideQuestionCardsAnswer } from '../components/slides/slide-view/SlideViewQuestionCards'
import { checkSlideQuestionMultiInputsAnswer } from '../components/slides/slide-view/SlideViewQuestionMultiInputs'

export interface SlidesState {
  curSlideIdx: number
  markedSlides: string[]
  userAnswers: {
    'question-checkboxes': Record<
      string,
      { value: number[]; isCorrect: boolean; slideIdx: number; }
    >
    'question-cards': Record<
      string,
      { value: number[]; isCorrect: boolean; slideIdx: number; }
    >

    'question-multi-inputs': Record<
      string,
      { value: string[]; isCorrect: boolean; slideIdx: number; }
    >
    'question-input': Record<string, { value: string; isCorrect: boolean; slideIdx: number; }>
  }
  /**
   * - if undefined the slide has not been submitted yet
   * - if null the slide is not question
   * - otherwise the slide is question and the boolean value is the result of the answer
   */
  answerResults: Record<string, boolean | null>
  userTotalPoints: number
  lastReachedIdx: number
}

const initialState: SlidesState = {
  curSlideIdx: 0,
  lastReachedIdx: 0,
  markedSlides: [],
  userAnswers: {
    'question-checkboxes': {},
    'question-cards': {},
    'question-multi-inputs': {},
    'question-input': {},
  },
  answerResults: {},
  userTotalPoints: 0,
}

export interface SubmitAnswerPayload {
  slide: QuestionCheckboxesSlide | QuestionInputSlide | QuestionCardsSlide | QuestionMultiInputsSlide
  userAnswer: any
}

export const slidesState = createSlice({
  name: 'slides',
  initialState,
  reducers: {
    resetSlidesState: () => initialState,
    submitAnswer: (
      state,
      { payload: { slide, userAnswer } }: PayloadAction<SubmitAnswerPayload>
    ) => {
      if (state.userAnswers[slide.type][slide.id]) return

      let isQuestion = true;
  
      let isCorrect = false
      if (slide.type === 'question-checkboxes') {
        isCorrect = checkSlideQuestionCheckboxAnswer(slide, userAnswer)
      } else if (slide.type === 'question-cards') {
        isCorrect = checkSlideQuestionCardsAnswer(slide, userAnswer)
        isCorrect = checkSlideQuestionCardsAnswer(slide, userAnswer)
      } else if (slide.type === 'question-multi-inputs') {
        isCorrect = checkSlideQuestionMultiInputsAnswer(slide, userAnswer)
      } else if (slide.type === 'question-input') {
        isCorrect = checkSlideQuestionInputAnswer(slide, userAnswer)
      } else {
        isQuestion = false;
      }

      state.userAnswers[slide.type][slide.id] = { isCorrect, value: userAnswer, slideIdx: state.curSlideIdx }

      if (isCorrect) state.userTotalPoints += slide.point

      state.answerResults[slide.id] = isQuestion ? isCorrect : null

      console.log('debug submitAnswer', state.userTotalPoints)
    },

// ------------------------------------------------------------------
    nextSlide: (state) => {
      state.curSlideIdx++
      if (state.curSlideIdx > state.lastReachedIdx) {
        state.lastReachedIdx = state.curSlideIdx
      }
    },
    prevSlide: (state) => {
      state.curSlideIdx = Math.max(state.curSlideIdx - 1, 0)
    },
    goToSlide: (state, { payload: slideIdx }: PayloadAction<number>) => {
      state.curSlideIdx = slideIdx;
    },
    setLastReachedIdx: (state, { payload: lastReachedIdx }: PayloadAction<number>) => {
      state.lastReachedIdx = lastReachedIdx
    },
// ------------------------------------------------------------------


    toggleSlideMark: (state, { payload: slideId }: PayloadAction<string>) => {
      if (state.markedSlides.includes(slideId)) {
        state.markedSlides = state.markedSlides.filter(
          (markedSlide) => markedSlide !== slideId
        )
      } else {
        state.markedSlides.push(slideId)
      }
    },
  },
})

export const {
  submitAnswer,
  nextSlide,
  prevSlide,
  goToSlide,
  toggleSlideMark,
  resetSlidesState,
  setLastReachedIdx
} = slidesState.actions

export const slidesReducer = slidesState.reducer
